import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Sometimes You Need To Be A Worker Ant`}</h1>
    <p><strong parentName="p">{`Day 7:`}</strong><br parentName="p"></br>{`
`}{`Today's update is going to be short & sweet.`}</p>
    <p>{`Writing these emails has taken a lot of time - so i'm going to work on keeping
them shorter and action packed with stories and insights for you`}</p>
    <p>{`*`}<strong parentName="p">{`tv show narrator`}</strong>{`*`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Previously on...the all access pass`}</strong></p>
    <p>{`Yesterday, we triggered a "public fomo fest" by
`}<a parentName="p" {...{
        "href": "https://twitter.com/ShaanVP/status/1300919656325083136"
      }}>{`tweeting out about the fund`}</a>{`,
and hyping it up everytime someone committed. This was obnoxious but effective.
(that describes me fairly well, maybe I should get t-shirts made).`}</p>
    <p>{`Originally the plan for today was to start working on the cold leads that Ben
scraped from LinkedIn...but we still have so many active conversations that
spending time on that would be silly.`}</p>
    <p><strong parentName="p">{`We need to be in "close mode"`}</strong>{`. Delays kill deals, so we dropped everything
to be constantly communicating with the prospective investors.`}</p>
    <p>{`I call this going into 6th gear. You don't use it often, but when you're ready
you laser focus and go,go, go.`}</p>
    <p><strong parentName="p">{`Today's Mission:`}</strong>{` `}<br />{` We left off yesterday at
$1.5M raised. If you remember the `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1wnZLXR3BDdyDZLLmcW3HkInJuC69s7ryDwHf1Sj2FOs/edit"
      }}>{`Kickoff Document`}</a>{`, this is past our "good win" goal, but not quite at the f*ck yea goal ($2.4m
for Year 1).`}</p>
    <p>{`Today our goal was to raise another $250k from the warm leads we got off
twitter.`}</p>
    <p>{`Yesterday I shared our script for the DMs. How we took a warm lead from
"interested" to "closed." Today we're doing the exact same thing. It's kind of
tedious, and doesn't sound cool to sit and answer the same questions over and
over again...but that's reality - and I promised this all access pass would show
you what I did each day.`}</p>
    <p>{`Sometimes we need to be smart, sexy, james bond style heroes figuring things
out.`}</p>
    <p>{`But when you find something that works, sometimes you just become monotonous
little worker ants, doing the same shit over and over again.`}</p>
    <p>{`So for today - I'm going to leave it at this! I really wanna get this fund to
$2M - so I'm going to get back to it!`}</p>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <p><strong parentName="p">{`P.S. Here are 3 tabs from my browser history from the past week that i thought
you might like:`}</strong></p>
    <ul>
      <li parentName="ul">{`This investor I like (Geoff Lewis) has been posting on his youtube channel
daily. It's pretty interesting, both the style of his vids, and the content.
He's robotic as hell, but also smart as hell. The videos only have like 200
views, so nobody's really watching, but it's a hidden gem.
`}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=hOHc53WmD1s"
        }}>{`Get ‘em here`}</a></li>
      <li parentName="ul">{`I've never watched curb your enthusiasm, but somehow
`}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=B2oLFKYNInQ"
        }}>{`the clips show up in my youtube feed`}</a>{`
and I love it. I just bounce from funny clip to funny clip, without ever
needing to watch the show.`}</li>
      <li parentName="ul">{`If you listen to the podcast, you know i’m big into storytelling. I love this
book,
`}<a parentName="li" {...{
          "href": "https://www.amazon.com/Storyworthy-Engage-Persuade-through-Storytelling/dp/1608685489"
        }}>{`storyworthy`}</a>{`, -
but here’s an even better
`}<a parentName="li" {...{
          "href": "https://matthewdicks.com/appearances/2015-12-13-tedx-berkshires-homework-for-life/"
        }}>{`15 minute Ted Talk`}</a>{`
by the author`}</li>
    </ul>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/document/d/1wnZLXR3BDdyDZLLmcW3HkInJuC69s7ryDwHf1Sj2FOs/edit',
        label: 'Fund Kickoff Google Doc'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/ShaanVP/status/1300919656325083136',
        label: "Shaan's tweet announcing the rolling fund"
      }, {
        url: 'https://www.youtube.com/watch?v=hOHc53WmD1s',
        label: "Geoff Lewis's YouTube Channel"
      }, {
        url: 'https://www.youtube.com/watch?v=B2oLFKYNInQ',
        label: "Curb Your Enthusiasm: MAGA Hat"
      }, {
        url: 'https://www.amazon.com/Storyworthy-Engage-Persuade-through-Storytelling/dp/1608685489',
        label: "Storyworthy Book"
      }, {
        url: 'https://matthewdicks.com/appearances/2015-12-13-tedx-berkshires-homework-for-life/',
        label: 'Homework for Life TED Talk'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.how_1_tweet_turned_into_1_5_m.label,
      slug: slugify(postMap.how_1_tweet_turned_into_1_5_m.slug)
    }} nextButton={{
      text: postMap.good_artists_copy_great_artists_steal.label,
      slug: slugify(postMap.good_artists_copy_great_artists_steal.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      