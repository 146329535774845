import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`24 hours of learning about collectibles`}</h1>
    <p>{`Yo,`}</p>
    <p>{`This is Part II of the crazy collectibles story. If you missed yesterday’s post, `}<a parentName="p" {...{
        "href": "/app/posts/24-hours-of-learning-about-collectibles"
      }}>{`here’s Part I`}</a>{`.`}</p>
    <p>{`So I woke up today thinking - what the hell was I thinking?? Why would I buy a $10M+ card collection?`}</p>
    <p>{`Then I laughed. And carried on digging in.`}</p>
    <p><strong parentName="p">{`We set up calls this evening, with people who knew stuff about the collectibles market.`}</strong></p>
    <ul>
      <li parentName="ul">{`Jack - one of the biggest collectable investors I know`}</li>
      <li parentName="ul">{`Nick - self-proclaimed Pokemon guru, also working at one of my favorite startups right now (`}<a parentName="li" {...{
          "href": "https://click.convertkit-mail2.com/k0ux4vq3qrt6hrg84efl/n2hohvhnw85ovvc6/aHR0cHM6Ly93d3cubWFpbnN0cmVldC51cy8="
        }}>{`Mainstreet`}</a>{`)`}</li>
      <li parentName="ul">{`Brian - A card expert who’s run different businesses in the space for 20+ years and a fellow AAP member (whatup Brian!)`}</li>
      <li parentName="ul">{`Nat - founded flatiron health and is really into the card game - even has an `}<a parentName="li" {...{
          "href": "https://click.convertkit-mail2.com/k0ux4vq3qrt6hrg84efl/48hvheh0z5q2m6tx/aHR0cHM6Ly93d3cuaW5zdGFncmFtLmNvbS9uYXRzdHVybmVyX2NhcmRzLz9obD1lbg=="
        }}>{`IG handle of everything he pulls`}</a>{`.`}</li>
      <li parentName="ul">{`Gary V - you probably know him.`}</li>
      <li parentName="ul">{`Rally Road Founders - They own a big platform for buying stakes in collectables.`}</li>
      <li parentName="ul">{`Otis Founders - same as Rally.`}</li>
    </ul>
    <p>{`I knew 1 of these people before. The rest I reached out to cold. Scroll down `}<a parentName="p" {...{
        "href": "/app/posts/impulse-vs-repeat-vs-splurge-products"
      }}>{`halfway in this post`}</a>{` if you wanna see how I usually do my cold DMs.`}</p>
    <p><strong parentName="p">{`Here’s what I learned:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`When you sell cards, you want them to be “Graded”`}</strong>{` (inspected for authenticity/condition). Perfect condition is a “10”. There’s 2 agencies out there everyone uses: PSA and BGS.`}</li>
      <li parentName="ul"><strong parentName="li">{`Getting cards graded is expensive.`}</strong>{` It can range from $10-$200 depending on the speed (quick turnaround vs. normal) and the value of the card (because they actually have to get insurance when handling valuable cards). So….getting this collection graded is great for selling...but no way I can spend $10 x 1M+ cards.`}</li>
      <li parentName="ul"><strong parentName="li">{`Not an easy asset to flip fast`}</strong>{`, it’ll take tons of time to sell everything. Eww.`}</li>
      <li parentName="ul">{`One of the people I talked to has a full team in the Philippines that looks up card values on ebay and `}<strong parentName="li">{`takes advantage of arbitrage opportunities that exist right now`}</strong>{`.`}</li>
    </ul>
    <p>{`The biggest issue is time. I’m down to make money buying/selling an asset. I’m not interested in anything that sucks up time.`}</p>
    <p>{`So far 6 out of 6 people told me “this will take a lot of time”. So this tells me that the real question to ask is:`}</p>
    <p>{`“If I was going to do this...what is the hack that lets me pull this off with zero effort?”`}</p>
    <p>{`Most people roll their eyes when I talk like this.`}</p>
    <p>{`I don’t blame them. I get it. It’s kinda obnoxious.`}</p>
    <p><strong parentName="p">{`Every person I called was basically like:`}</strong></p>
    <ul>
      <li parentName="ul">{`So you don’t know shit about cards`}</li>
      <li parentName="ul">{`Yet you’re considering buying a multi-million dollar collection`}</li>
      <li parentName="ul">{`AND on top of that, you’re not listening to me, someone who’s been doing this for years..when I tell you that this won’t be some quick flip.`}</li>
    </ul>
    <p>{`But this is the one advantage of being an outsider. I think differently. I don’t know whatever the “normal” way to do something is...so there’s a chance I can come up with a completely new solution.`}</p>
    <p>{`For example..instead of selling to an auction house, or finding a big buyer to flip it to… what if we sold off the collection in $100 mystery boxes to 100k people. Would people pay $100 to open up a box that might have a $10k or $30k card inside?`}</p>
    <p>{`What if we partner with a big youtuber like MrBeast to do a video where we give the whole thing away or do a sweepstakes for it.`}</p>
    <p>{`Who knows. There’s a 99% chance we don’t do anything with it. But this is how I train my brain to think creatively. To learn. And become business McGuyver who can figure out a way to solve any business problem.`}</p>
    <p>{`One last call setup tomorrow. If inspiration strikes, we’ll go for it. If not, we’ll say f*ck it and move on!`}</p>
    <p>{`-`}{` Shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      