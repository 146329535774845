import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`It's Monday and I wanna help you nail your story`}</h1>
    <p>{`It’s monday - and i dunno about you, but I’m following my niece Zoey’s monday
schedule:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "408px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "162.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAgABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAG62D83pMKD85hpFAP/xAAcEAACAgIDAAAAAAAAAAAAAAAAAgEDEBIREyD/2gAIAQEAAQUCitJIqQ60F8c40Q1rNKj/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAADAQEAAAAAAAAAAAAAAAAAATLhIP/aAAgBAQAGPwKUSiF3ppp//8QAGxABAQACAwEAAAAAAAAAAAAAAQARITFBkVH/2gAIAQEAAT8h5Dzl0GYPGW45sw7usOo+UG5hg6v/2gAMAwEAAgADAAAAELcijv/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB8QAQACAQMFAAAAAAAAAAAAAAEAETFBUXEhkaHB0f/aAAgBAQABPxBotO9Y1SXCkLUHGVm4KKOZbRlY3IW6MxkQTnzaCFjx9zUO79n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Monday Schedule",
          "title": "Monday Schedule",
          "src": "/static/972741fe37553f9ea8443373331a73d3/44f55/monday-schedule.jpg",
          "srcSet": ["/static/972741fe37553f9ea8443373331a73d3/a6407/monday-schedule.jpg 400w", "/static/972741fe37553f9ea8443373331a73d3/44f55/monday-schedule.jpg 408w"],
          "sizes": "(max-width: 408px) 100vw, 408px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Also a fun little link:
`}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://judiciary.house.gov/uploadedfiles/0010143800101441.pdf"
        }}>{`This is the leaked SMS`}</a></strong>{`
conversation between Kevin Systrom and his investor talking about the potential
of Facebook buying instagram. Love these backstories.`}</p>
    <p><strong parentName="p">{`I have an update about the course, and then answered questions in the
mailbag.`}</strong></p>
    <p><strong parentName="p">{`Course Update`}</strong></p>
    <p>{`As you saw, on Friday I was debating what topic to base my course around.`}</p>
    <p>{`So I said screw it.`}</p>
    <p>{`I’m using one of the Twitch values: “Experiment to Decide”`}</p>
    <p>{`Rather than commit to one topic, I’m going to experiment by doing a 2-hour
workshop for one of the ideas this week - and see how people like it, see if i
like it etc..`}</p>
    <p><strong parentName="p">{`So this Sunday at 1pm PST, I’m doing a 2 hour workshop where you’ll learn to
Nail Your Story. It’s a crash course in everything I’ve learned about
storytelling from people who are actually great at it.`}</strong></p>
    <p><strong parentName="p">{`You’ll walk away with:`}</strong></p>
    <ul>
      <li parentName="ul">{`Your One-Line-Story for your brand (either personal or company)`}</li>
      <li parentName="ul">{`Knowing the difference between a boring story and a great story`}</li>
      <li parentName="ul">{`A crafted story you can use as a leader, or in public speaking, ice breakers
etc..`}</li>
      <li parentName="ul">{`Tools you can use to become a better storyteller if you so choose after this`}</li>
    </ul>
    <p><del parentName="p"><strong parentName="del"><a parentName="strong" {...{
            "href": "https://hopin.com/events/nail-your-story"
          }}>{`If you wanna reserve your spot, RSVP here and add it to your calendar.`}</a></strong></del></p>
    <p><strong parentName="p">{`What if i can’t make it?`}</strong>{` There will be a recording + we will send out an
email(s) with a recap.`}</p>
    <p><strong parentName="p">{`Afterwards, we’ll spin up a #storytelling slack channel to discuss everything
related to storytelling and get additional feedback.`}</strong></p>
    <p><strong parentName="p">{`Last Week At A Glance`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/app/posts/fridays-ama-25-day-recap-on-the-way"
          }}>{`AMA Recap`}</a></strong></li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/app/posts/just-the-best-stuff-so-far"
          }}>{`Key Takeaways - the best thing we ever did`}</a></strong></li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/app/posts/debating-isn-t-just-for-presidents"
          }}>{`Debating Isn't Just for President's`}</a></strong></li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/app/posts/behind-the-scenes-of-the-first-fund-lp-update"
          }}>{`A look under the hood of running a fund`}</a></strong></li>
    </ul>
    <p><strong parentName="p">{`The Mailbag`}</strong></p>
    <p>{`We skipped this last week, so i’m back answering your questions. It’ll be short
and sweet today!`}</p>
    <p><strong parentName="p">{`What is one thing you wish you could tell yourself at age 22?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Josh, a 22-year old self proclaimed e-comm nerd`}</em></p>
    <p>{`Enjoy being 22. Travel. Hang with your friends. Date. Don’t spend so much time
playing poker. Reach out to the founders of all your favorite products. Become
friends with them, just hanging with them will make you smarter & more
ambitious. Oh and put all your money (and your Dad’s) into `}{`$`}{`AMZN stock !`}</p>
    <p><strong parentName="p">{`I’m interested in cloud kitchens and was wondering what happened with your
first restaurant in the space. Specifically, were there any mistakes you made
that I could learn from?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Dillan from London`}</em></p>
    <p>{`We didn’t use digital ads to market our restaurant. We did door to door sales
downtown (technically, floor to floor sales in skyscrapers, selling to the
receptionists). We tried going viral on youtube (wtf?). We should have been
doing facebook ads or referral programs to get customers. We were also 5+ years
too early, because uber eats/doordash etc.. didn’t exist back then to build on
top of!`}</p>
    <p><strong parentName="p">{`I'm currently looking into launching courses with influencers. Do you have any
advice on how to structure deals in terms of the splits based on your experience
doing this for your course?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Dev`}</em></p>
    <p>{`I’d start with something like 20-30% rev share for the first influencer. That
will tell you that they value your contribution, without being a big scary
number to slow down your deal. For the first influencer, you really just wanna
get going, so give a little extra to them if you need to. Probably a bigger term
is to make sure the influencer agrees to invest some of their own `}{`$`}{` into the
promotion/production. Influencer’s tend to be scatterbrained, so getting them to
put their skin in the game helps keep them working.`}</p>
    <p><strong parentName="p">{`At the validation stage for the digital product, there are lots of moving
pieces. What metrics would you focus on the most to ensure you're still moving
forward?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Manny`}</em></p>
    <p>{`Hmm. kind of a broad question. Not sure what exactly you mean. In general, I am
working backwards from success. Success = `}{`$`}{`X revenue, Y customers, and maybe Z
net promoter score. So then I’m just measuring my progress towards hitting those
metrics. But while testing the idea, I might test for simpler things like email
list growth, ad click thru rates etc. to gauge interest.`}</p>
    <p><strong parentName="p">{`Is there any equivalent of SBA loans for Europe that you know of? Can you
incorporate that into anything you do about buying businesses?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Patrik`}</em></p>
    <p>{`Unfortunately I don’t know about that for europe. When we go down that path,
I’ll find out. I have a friend doing this over there, so I can ask him.`}</p>
    <p><strong parentName="p">{`Can you share #'s for AAP (free and paid)? Do you expect AAP to make you as
much `}{`$`}{` as the other 3 businesses?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Matt`}</em></p>
    <p>{`I’d like to share the numbers at some point. Maybe we’ll do it this month? Need
to talk to ben. I tend to make rash decisions, so I’ll let him be the judge. And
no I don’t think AAP will make as much as the businesses (not close), but it is
nice cashflow!`}</p>
    <p><strong parentName="p">{`How did you become the framework guy?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`V from Norway`}</em></p>
    <p>{`I thought everyone thinks this way. Then my friends kept pointing out that I
seem to “always have a framework” as my answer to things. I think they were half
making fun of me, but it became something that I embraced. It works for me, but
not for others. Everyone has a different style, many ways to win.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`How do you know when to use each framework when you come up against a
specific problem?`}</em></strong><br parentName="p"></br>{`
`}<em parentName="p">{`V from Norway`}</em></p>
    <p>{`Charlie Munger describes this well. Each framework is a tiny mental hook. Then
as you experience things, you hang that fact/experience on the right hook that
it fits (or if it doesn’t fit/breaks your mental models, then you’ve learned
something new). The more you do that, the more helpful frameworks become.`}</p>
    <p>{`Love the questions. Keep em coming!`}</p>
    <p>{`-`}{` Shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      