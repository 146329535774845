import React, { useContext } from 'react';
import AuthContext from 'context/auth-context';
import { navigate } from 'gatsby';

type Props = {
  component: any;
  path: string;
};

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { user } = useContext(AuthContext);

  if (typeof window !== 'undefined') {
    if (!user) {
      navigate('/login');
      return null;
    }
  }

  return user ? <Component {...rest} /> : null 
};

export default PrivateRoute;
