// @ts-nocheck

import home from 'pages/app/posts';
import start_here from 'pages/app/posts/start-here';
import project_kickoff from 'pages/app/posts/project-kickoff';
import making_the_sales_map from 'pages/app/posts/making-the-sales-map';
import nailing_our_pitch from 'pages/app/posts/nailing-our-pitch';
import the_taste_of_rejection from 'pages/app/posts/the-taste-of-rejection';
import going_down_the_funnel from 'pages/app/posts/going-down-the-funnel';
import how_1_tweet_turned_into_1_5_m from 'pages/app/posts/how-1-tweet-turned-into-1-5-m'
import sometimes_you_need_to_be_a_worker_ant from 'pages/app/posts/sometimes-you-need-to-be-a-worker-ant';
import what_do_vacuums_gummies_chalkboards_all_have_in_common from 'pages/app/posts/what-do-vacuums-gummies-chalkboards-all-have-in-common';
import amas_audio_experiments_and_the_sunday_mailbag from 'pages/app/posts/amas-audio-experiments-and-the-sunday-mailbag';
import impulse_vs_repeat_vs_splurge_products from 'pages/app/posts/impulse-vs-repeat-vs-splurge-products';
import the_power_box from 'pages/app/posts/the-power-box';
import validating_demand_for_product_ideas from 'pages/app/posts/validating-demand-for-product-ideas';
import tools_tips_tricks_to_finding_a_manufacturer from 'pages/app/posts/tools-tips-tricks-to-finding-a-manufacturer';
import every_day_is_my_friday from 'pages/app/posts/every-day-is-my-friday';
import product_samples_are_on_the_way from 'pages/app/posts/product-samples-are-on-the-way';
import good_artists_copy_great_artists_steal  from 'pages/app/posts/good-artists-copy-great-artists-steal';
import hiring_a_good_operator_feels_like_magic from 'pages/app/posts/hiring-a-good-operator-feels-like-magic';
import bankshots_only_work_in_basketball from 'pages/app/posts/bankshots-only-work-in-basketball';
import back_again_with_the_sunday_mailbag from 'pages/app/posts/back-again-with-the-sunday-mailbag';
import using_big_mario_to_grow_the_aap_audience from 'pages/app/posts/using-big-mario-to-grow-the-aap-audience';
import spoiler_alert_targeting_tai_lopez_s_audience_is_working from 'pages/app/posts/spoiler-alert-targeting-tai-lopez-s-audience-is-working';
import prototyping_the_third_door from 'pages/app/posts/prototyping-the-third-door';
import its_all_about_taking_the_right_risks from 'pages/app/posts/its-all-about-taking-the-right-risks';
import the_internet_doesnt_care_about_geography from 'pages/app/posts/the-internet-doesnt-care-about-geography';
import recap from 'pages/app/posts/recap';
import picking_the_mission_for_the_week from 'pages/app/posts/picking-the-mission-for-the-week';
import hitting_a_plateau_should_be_like_greeting_an_old_friend from 'pages/app/posts/hitting-a-plateau-should-be-like-greeting-an-old-friend';
import were_not_selling_saddles_here from 'pages/app/posts/were-not-selling-saddles-here';
import fridays_ama_25_day_recap_on_the_way from 'pages/app/posts/fridays-ama-25-day-recap-on-the-way';
import just_the_best_stuff_so_far from 'pages/app/posts/just-the-best-stuff-so-far';
import debating_isn_t_just_for_presidents from 'pages/app/posts/debating-isn-t-just-for-presidents';
import behind_the_scenes_of_the_first_fund_lp_update from 'pages/app/posts/behind-the-scenes-of-the-first-fund-lp-update';
import its_monday_and_i_wanna_help_you_nail_your_story from 'pages/app/posts/its-monday-and-i-wanna-help-you-nail-your-story';
import today_was_a_total_curveball from 'pages/app/posts/today-was-a-total-curveball';
import twenty_four_hours_of_learning_about_collectibles from 'pages/app/posts/24-hours-of-learning-about-collectibles';
import building_the_flywheel from 'pages/app/posts/building-the-flywheel';
import painting_a_picture_while_tickling_the_brain from 'pages/app/posts/painting-a-picture-while-tickling-the-brain';
import growth_is_math_art_science from 'pages/app/posts/growth-is-math-art-science';
import the_art_of_non_violent_communication from 'pages/app/posts/the-art-of-non-violent-communication';
import firing_lots_of_growth_bullets from 'pages/app/posts/firing-lots-of-growth-bullets';
import finding_the_bug_in_the_system from 'pages/app/posts/finding-the-bug-in-the-system';
import the_more_i_know_about_facebook_ads_the_better from 'pages/app/posts/the-more-i-know-about-facebook-ads-the-better';
import i_hate_flying_blind_so_i_made_a_roas_calculator from 'pages/app/posts/i-hate-flying-blind-so-i-made-a-roas-calculator';
import todays_ama_recording from 'pages/app/posts/todays-ama-recording';
import how_id_make_100k_in_a_week_right_now from 'pages/app/posts/how-id-make-100k-in-a-week-right-now';
import we_got_our_sample from 'pages/app/posts/we-got-our-sample';
import im_obsessed_with_this_business_model from 'pages/app/posts/im-obsessed-with-this-business-model';
import the_sunday_mailbag_a_special_guest_coming_this_week from 'pages/app/posts/the-sunday-mailbag-a-special-guest-coming-this-week';
import tofu_isnt_just_something_i_like_to_eat from 'pages/app/posts/tofu-isnt-just-something-i-like-to-eat';
import ripping_the_bandaid_off from 'pages/app/posts/ripping-the-bandaid-off';
import my_life_vision from 'pages/app/posts/my-life-vision';
import three_ecom_tactics_that_drove_30k_in_incremental_revenue from 'pages/app/posts/3-ecom-tactics-that-drove-30k-in-incremental-revenue';
import my_5_lessons_learned_from_jack_butcher from 'pages/app/posts/my-5-lessons-learned-from-jack-butcher';


export default {
  home,
  start_here,
  project_kickoff,
  making_the_sales_map,
  nailing_our_pitch,
  the_taste_of_rejection,
  going_down_the_funnel,
  how_1_tweet_turned_into_1_5_m,
  sometimes_you_need_to_be_a_worker_ant,
  what_do_vacuums_gummies_chalkboards_all_have_in_common,
  amas_audio_experiments_and_the_sunday_mailbag,
  impulse_vs_repeat_vs_splurge_products,
  the_power_box,
  validating_demand_for_product_ideas,
  tools_tips_tricks_to_finding_a_manufacturer,
  every_day_is_my_friday,
  product_samples_are_on_the_way,
  good_artists_copy_great_artists_steal,
  hiring_a_good_operator_feels_like_magic,
  bankshots_only_work_in_basketball,
  back_again_with_the_sunday_mailbag,
  using_big_mario_to_grow_the_aap_audience,
  spoiler_alert_targeting_tai_lopez_s_audience_is_working,
  prototyping_the_third_door,
  its_all_about_taking_the_right_risks,
  the_internet_doesnt_care_about_geography,
  recap,
  picking_the_mission_for_the_week,
  hitting_a_plateau_should_be_like_greeting_an_old_friend,
  were_not_selling_saddles_here,
  fridays_ama_25_day_recap_on_the_way,
  just_the_best_stuff_so_far,
  debating_isn_t_just_for_presidents,
  behind_the_scenes_of_the_first_fund_lp_update,
  its_monday_and_i_wanna_help_you_nail_your_story,
  today_was_a_total_curveball,
  twenty_four_hours_of_learning_about_collectibles,
  building_the_flywheel,
  painting_a_picture_while_tickling_the_brain,
  growth_is_math_art_science,
  the_art_of_non_violent_communication,
  firing_lots_of_growth_bullets,
  finding_the_bug_in_the_system,
  the_more_i_know_about_facebook_ads_the_better,
  i_hate_flying_blind_so_i_made_a_roas_calculator,
  todays_ama_recording,
  how_id_make_100k_in_a_week_right_now,
  we_got_our_sample,
  im_obsessed_with_this_business_model,
  the_sunday_mailbag_a_special_guest_coming_this_week,
  tofu_isnt_just_something_i_like_to_eat,
  ripping_the_bandaid_off,
  my_life_vision,
  three_ecom_tactics_that_drove_30k_in_incremental_revenue,
  my_5_lessons_learned_from_jack_butcher
}