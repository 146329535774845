import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Behind the Scenes of the First Fund LP Update`}</h1>
    <p>{`Hey y'all,`}</p>
    <p>{`Today I want to share the investor update draft I’m sending to our fund investors.`}</p>
    <p>{`Updates are not just some task or requirement to do. They are an opportunity.`}</p>
    <p>{`As my friend Daniel Gross puts it, it’s an opportunity to rent a billboard inside wealthy people’s heads for 10 minutes. You get to put yourself top of mind, and create opportunities for serendipity. You get to deposit something into the trust bank account they have with your name on it.`}</p>
    <p>{`That’s why I always took time to write investor updates. Sharing this one with you all access pass members, with some key ＃s redacted that are for investor eyes only.`}</p>
    <p><strong parentName="p">{`Q4 Fund Update`}</strong></p>
    <p>{`Dear Investors -`}</p>
    <p>{`I wanted to drop in a quick update about the fund, and our investments from the first month of operating!`}</p>
    <p><strong parentName="p">{`The Numbers`}</strong></p>
    <ul>
      <li parentName="ul">{`Deals Seen: 60+`}</li>
      <li parentName="ul">{`New Investments: 7`}</li>
      <li parentName="ul">{`Amount Invested: $550,000`}</li>
    </ul>
    <p>{`(incl. 3 angel investments I rolled into the fund)`}</p>
    <p><strong parentName="p">{`Capital Remaining Q4: ~$50k`}</strong></p>
    <p><strong parentName="p">{`Fund Updates`}</strong><br parentName="p"></br>{`
`}{`We brought on a fund manager (Romeen Sheth), a lead scout (Zach Waterfield), and a head of indian investments (Suumit Shah).`}</p>
    <p>{`Romeen is super sharp. He’s harvard law, duke undergrad. Currently runs a biz doing tens of millions in revenue per year, and has his own podcast called Square One that has helped him build a good network in silicon valley.`}</p>
    <p>{`Zach is an ex YC founder based in canada, with a technical background. He’s been amazing, he showed us over 30+ deals already this month. Summit is the founder of Dukaan, a company I invested in india. Super plugged in, and helped source one deal this quarter (see below).`}</p>
    <p>{`These guys are helping me and Ben make the most out of this fund. This is a crack team, and I’m getting smarter everyday debating deals with them.`}</p>
    <p><strong parentName="p">{`Companies we’re investing in:`}</strong></p>
    <p>{`I’ll break it down into three trends.`}</p>
    <ul>
      <li parentName="ul">{`Digital Health & Fitness`}</li>
      <li parentName="ul">{`Internet Infrastructure`}</li>
      <li parentName="ul">{`Wildcards`}</li>
    </ul>
    <p><strong parentName="p">{`Let’s start with Digital Health & Fitness`}</strong></p>
    <p><strong parentName="p">{`What’s the big trend?`}</strong></p>
    <p>{`One trend we noticed is that fitness is being digitized. Things we used to do in the gym, we’re doing at home (and COVID really accelerated this behavior shift). Things we used to do with human personal trainers, we’re now doing with virtual trainers, for a fraction of the cost.`}</p>
    <p>{`We invested in two companies in this space so far. FitnessAI and MyYogaTeacher.`}</p>
    <p><strong parentName="p">{`FitnessAI`}</strong>{` = a virtual personal trainer, to help people gain muscle through weight lifting.`}</p>
    <p><strong parentName="p">{`MyYogaTeacher`}</strong>{` = a marketplace where yoga students can find teachers who do 1:1 yoga classes over zoom (they match US students with Yogi’s in India, where yoga originated).`}</p>
    <p>{`Both apps take something people want (personal instructions) and drop the price by ~10x by offering either an AI trainer or an overseas trainer over zoom.`}</p>
    <p>{`At home fitness is not new. From Jane Fonda’s at home aerobics, to the P90X/Beachbody programs of the 2000s, we have seen this story before. But some key things have changed:`}</p>
    <ul>
      <li parentName="ul">{`The product changes daily (unlike VHS or DVDs that never change)`}</li>
      <li parentName="ul">{`Facebook ads lets anyone get users quickly (rather than infomercials)`}</li>
      <li parentName="ul">{`Your customers stay subscribed and you can send push notifications (unlike on- time purchases)`}</li>
    </ul>
    <p>{`Both companies are doing over `}{`[REDACTED]`}{` ARR, with very sticky subscriber retention, and are in big markets (yoga + weight training).`}</p>
    <p>{`I believe every fitness sub-category will have a billion dollar+ winner. Peloton ($40B) is the winner of cycling/spin. Sweat is a female focused pilates/toning app doing over $100M in revenue. Calm & Headspace are worth ~$1B+ in digital mental health. We are hoping that these two companies can become the category kings of their vertical (Yoga & Weightlifting)`}</p>
    <p><strong parentName="p">{`Internet Infrastructure`}</strong></p>
    <p>{`One of the best investments of the past 20 years would have been Stripe. It’s a $30B+ company already, and smart people like Paul Graham are still seeing so much upside that he said: “calling it now, stripe is the next google”`}</p>
    <p>{`What makes Stripe so great?`}</p>
    <ul>
      <li parentName="ul">{`They built something nearly every internet company needs (payments)`}</li>
      <li parentName="ul">{`They made it stupidly simple for developers to use it (just a few lines of code)`}</li>
      <li parentName="ul">{`Therefore, as the internet grows, they will grow.`}</li>
    </ul>
    <p>{`Oh also, the founders are top 0.00001% geniuses with huge ambition.`}</p>
    <p>{`Me (and every other investor with two thumbs) wants to find the next stripe.`}</p>
    <p>{`In looking for things with those 3 traits, we found two contenders this quarter.`}</p>
    <p><strong parentName="p">{`Magic & Doppler.`}</strong></p>
    <p>{`Let’s start with Magic. Magic is tackling one of the most widely used parts of internet infrastructure: Passwords.`}</p>
    <p>{`Passwords suck. They are insecure, hard to type in, and are the cause for ~80% of data breaches (which cost companies hundreds of millions a year, and costs customers their privacy/financial security).`}</p>
    <p>{`Magic has created a login system that let’s people login with a “magic link” that gets emailed to them, rather than a password.`}</p>
    <ol>
      <li parentName="ol">{`This is something nearly every internet company needs (a login/password system)`}</li>
      <li parentName="ol">{`They made it stupidly simple for developers (just a few lines of code)`}</li>
      <li parentName="ol">{`Therefore, if they succeed at building a better password system, they will grow with the internet.`}</li>
    </ol>
    <p>{`Just like a lamborghini is designed to drive fast. Startups like this are designed to grow fast if they hit the nail on the head.`}</p>
    <p>{`And in startups, fast growth == big outcomes. We hunting lambos.`}</p>
    <p>{`They have over `}{`[REDACTED]`}{` developers signed up already, and are growing 25% a month for the past year. I saw a few of my friends apps using it, so I cold DM’d the founder to ask to invest:`}</p>
    <p><img parentName="p" {...{
        "src": "https://uploads-ssl.webflow.com/5f469ae4ab14c1cbb4428661/5f8dc0b68da7293880022776_https%253A%252F%252Fembed.filekitcdn.jpeg",
        "alt": null
      }}></img></p>
    <p>{`We’re investing `}{`[REDACTED]`}{` at a `}{`[REDACTED]`}{` valuation alongside great investors like Naval, Lightspeed, SV Angel etc..`}</p>
    <p>{`Doppler is very similar.`}</p>
    <p>{`They are solving a slightly nerdier version of the problem. You see - every engineering project has what they call “secrets”. Not the “harry kissed suzy” kind, but things like tokens, API keys, etc..`}</p>
    <p>{`Doppler has built a secret management system that developers actually like to use (which is quite the feat).`}</p>
    <p>{`Anytime developers tell me something is the BEST, or how much they LOVE something that used to be a pain in the ass, I pay attention. That’s how devs described stripe, twilio, github etc..`}</p>
    <p>{`I am very bullish on internet infrastructure and dev tools. Developers shouldn’t waste time building something out that isn’t the core differentiator of their product. Basic things like payments, logins, and secret management should be basic off-the-shelf tools you grab when you’re building your web app.`}</p>
    <p>{`We’re investing `}{`[REDACTED]`}{` at a `}{`[REDACTED]`}{` valuation alongside `}{`[REDACTED]`}{` `}{`[REDACTED]`}{` and `}{`[REDACTED]`}{`.`}</p>
    <p><strong parentName="p">{`The last category we invested in I’ll call “Wildcards”.`}</strong></p>
    <p>{`These are non-traditional founders, products or industries.`}</p>
    <p>{`The first is an app called `}<strong parentName="p">{`Wise`}</strong>{` out of India. I had heard these guys had built an online education app that let teachers teach students over video that works on 2G phones in india. I got excited.`}</p>
    <p>{`One of the founders was a tutor/teacher himself. Afterschool education is a huge market in india. Here, you get a tutor if you’re falling behind. In India, every parent is paranoid you might fall behind, so they get a tutor preemptively :)`}</p>
    <p>{`When COVID hit, he had to shut down his tutoring sessions. So the two founders got together and built Wise, an app that lets any tutor teach their students online. You create a class, share on whatsapp, and then teach over video. They are growing fast, they have over `}{`[REDACTED]`}{` students in just two months.`}</p>
    <p>{`I committed `}{`[REDACTED]`}{` on the spot in their seed round alongside my friend Ryan Hoover.`}</p>
    <p>{`The other wildcard company is called `}<strong parentName="p">{`Leda Health`}</strong>{`.`}</p>
    <p>{`They are going into a space that badly needs innovation, but is rarely talked about: Rape Kits.`}</p>
    <p>{`The founder Madison is an absolute firecracker. She’s young, and very impressive. Traditional rape kits are an awful user experience. You typically go to a hospital, fill out lots of paperwork, and get some uncomfortable swaps done to you. It’s a tough thing to do, right after an awful thing has happened to you. That’s a big reason why ~70% of sexual assaults go unreported.`}</p>
    <p>{`Leda is fixing that. They have made a direct to consumer, at home kit. With a discrete box, simple instructions, and evidence collection kit that can be done in private.`}</p>
    <p>{`They are selling these en masse to universities (thousands of kits per year, per university) to give them to every incoming student during orientation. Universities are a hotbed for sexual assault (crazy numbers like 1 in 4 women are assaulted while at university).`}</p>
    <p>{`They have a few universities on board, and a bunch more in the pipeline. They can get to $10M/year in revenue just off the kits to universities, and then I could see them extend into other subscription offerings such as ongoing digital therapy, support groups etc..`}</p>
    <p>{`As a dad with a daughter. I hope this company wins for more reasons than just our investment.`}</p>
    <p>{`We invested `}{`[REDACTED]`}{` at a `}{`[REDACTED]`}{` cap.`}</p>
    <p>{`It’s been a hell of a month - and we’re excited to see what new companies we meet next.`}</p>
    <p>{`If you have any companies that you think are/will grow fast - you know who to send them to :)`}</p>
    <p>{`-`}{` Shaan`}</p>
    <PostFooter prevButton={{
      text: postMap.hiring_a_good_operator_feels_like_magic.label,
      slug: slugify(postMap.hiring_a_good_operator_feels_like_magic.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      