import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Building The Flywheel`}</h1>
    <p>{`Yo!`}</p>
    <p>{`Quick update on the `}{`$`}{`10m+ card collection:`}</p>
    <p>{`We came to the conclusion that the effort/time it would take to extract value
out of the asset is too high for us, so we’re backing away.`}</p>
    <p>{`I used this as an excuse to talk to a whole bunch of interesting people (Gary V,
Justin Caldbeck, Jack Smith etc..), and to learn about an interesting/growing
niche industry.`}</p>
    <p><strong parentName="p">{`Even though I won’t be buying the collection, I did come away with some
interesting things:`}</strong></p>
    <ul>
      <li parentName="ul">{`The growth & passion of this niche hobby is impressive. I’m going to invest in
one (or all) of the platforms that are surfing this wave (Rally Rd, Otis, Alt,
Whatnot etc..). Reminds me of Twitch & StockX.`}</li>
      <li parentName="ul">{`Discovered a company called PWCC that is doing a very interesting take on
this. They have a `}{`$`}{`3M+ vault under their office where they store cards, and
they actually lend money against the cards. I can become a lender and get ~9%
on my money. They’ve had zero defaults in years & hold the cards as
collateral. I’m going to take some cash and lend it there.`}</li>
      <li parentName="ul">{`Someone I talked to is in a very similar situation, but with a giant `}{`$`}{`10M+
collection of unopened Pokemon sets. We bounced around the idea of how I might
help him take advantage of that situation.`}</li>
    </ul>
    <p>{`The biggest lesson here - do interesting things - this attracts interesting
people - and you’ll find interesting opportunities you couldn’t foresee ahead of
time (like Steve Jobs says, you can’t connect the dots looking forward).`}</p>
    <p>{`I’ll let you know how these play out.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`AAP Update:`}</strong></p>
    <p>{`Let’s do a quick recap on where we are:`}</p>
    <p><strong parentName="p">{`Venture #1 - Raise a `}{`$`}{`1M+ fund`}</strong>{` = success, fund is now at `}{`$`}{`2.7M, hired a
fund manager to run it, and we’ve made 10 investments so far.
(`}<a parentName="p" {...{
        "href": "/app/posts/behind-the-scenes-of-the-first-fund-lp-update"
      }}><strong parentName="a">{`here's last week's investor update`}</strong></a>{`)`}</p>
    <p><strong parentName="p">{`Venture #2 - `}{`$`}{`1m+ Ecommerce Biz =`}</strong>{` in progress. We picked our top products
and ordered samples. Waiting for them to arrive.`}</p>
    <p><strong parentName="p">{`Venture #3 - `}{`$`}{`1m+ Online Course =`}</strong>{` in motion, look below:`}</p>
    <p>{`I talked to 5 people who have `}{`$`}{`1M+ courses. It became clear there are two
paths:`}</p>
    <ol>
      <li parentName="ol">{`Build a big organic audience, position yourself as the niche authority of
that topic, then offer a course and 2% of them will buy. (eg. 50k fans = 1k
students x $1000 course x 3 times a year = $3M/year)`}</li>
      <li parentName="ol">{`Go aggressive w paid ads, setup a clickfunnel, grab emails/phone numbers,
email & cold call people to convince them to buy a high ticket (`}{`$`}{`4k+) course
over the phone`}</li>
    </ol>
    <p>{`Looking at these two, I prefer #1.`}</p>
    <p>{`I called my friend Ryan Begelman to ask him if I was being a b`}{`*`}{`tch or over
thinking it and he was like “nah man I’ve thought about the same thing. I love
to teach. I love to make money. But the idea of me putting up instagram ads
telling people to take my course and overcome their fears or use my 7 easy steps
to get rich...makes me wanna shoot myself in the head”.`}</p>
    <p>{`Haha. OK good. #1 it is.`}</p>
    <p>{`It’s a slower path, but more valuable in the end, and I don’t have to feel icky
because I’m not using high pressure sales tactics.`}</p>
    <p>{`Unfortunately, that means it’s going to go slower. `}<strong parentName="p">{`First,`}</strong>{` we’re going to
build a big audience, `}<strong parentName="p">{`Then,`}</strong>{` we’re going to offer a course. Our goal is to
grow our free audience from 8k to 50k in the next 50 days.`}</p>
    <p>{`If you’ve ever been interested in building up a big audience or newsletter, this
is a good chance to watch how we do it.`}</p>
    <p>{`So… where are we starting?`}</p>
    <p><strong parentName="p">{`Step 1 - Pick the Niche I want to become “known for”`}</strong></p>
    <p>{`I brainstormed 5 high level buckets that I thought I could do:`}</p>
    <ol>
      <li parentName="ol">{`Startup ideas (like the podcast)`}</li>
      <li parentName="ol">{`Frameworks / Mindset`}</li>
      <li parentName="ol">{`Buying a business`}</li>
      <li parentName="ol">{`Great Marketing`}</li>
      <li parentName="ol">{`Growth`}</li>
    </ol>
    <p><strong parentName="p">{`Step 2 - kickoff doc
(`}<a parentName="strong" {...{
          "href": "https://docs.google.com/document/d/1OEbOF-Gz9a324aOEZaRez9f0KieqqMSFUBqUtYfWt0Y/edit"
        }}>{`check it out here`}</a>{`)`}</strong></p>
    <p><strong parentName="p">{`Step 3 - Figure out the flywheel`}</strong></p>
    <p>{`If you’re not familiar with flywheels - listen up.`}</p>
    <p>{`This is one of the most important business frameworks there is.`}</p>
    <p>{`We’ve all heard about “downward spirals”. When one bad thing leads to another
bad thing and so on..`}</p>
    <p>{`Flywheels are the opposite. It’s an upward spiral. One good thing leads to
another good thing, etc..`}</p>
    <p>{`(`}<a parentName="p" {...{
        "href": "https://theflywheel.substack.com/"
      }}><strong parentName="a">{`P.S. here’s a cool substack about the Uber flywheel`}</strong></a>{`)`}</p>
    <p>{`For me this is my flywheel:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "788px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7isioP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEDEBEx/9oACAEBAAEFAmzXcfD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAAREgMf/aAAgBAQAGPwKDHX//xAAZEAEAAgMAAAAAAAAAAAAAAAABEBEAMZH/2gAIAQEAAT8hS6GAb7XDJ//aAAwDAQACAAMAAAAQQ8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQRAx/9oACAEBAAE/EKhVvfhNkINK6Q0gEpLgFLrffP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan's Flywheel",
          "title": "Shaan's Flywheel",
          "src": "/static/ade247ebe3381a1b7a4089747c7dbed9/8e9d0/shaans-flywheel.jpg",
          "srcSet": ["/static/ade247ebe3381a1b7a4089747c7dbed9/a6407/shaans-flywheel.jpg 400w", "/static/ade247ebe3381a1b7a4089747c7dbed9/8e9d0/shaans-flywheel.jpg 788w"],
          "sizes": "(max-width: 788px) 100vw, 788px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now - what niche do we pick?`}</p>
    <p>{`This was basically an all-day debate.`}</p>
    <p>{`We landed on growth.`}</p>
    <p>{`I love to think of ways to grow my business, and those that I invest/advise in.`}</p>
    <p>{`Whether it’s growing from 0→ first 100 customers, or from $1M to $10M, or
$1B → $10B. I love growth at every stage. Whether you start a business from
scratch or buy a business. Everybody wants growth.`}</p>
    <p>{`So that’s the mission. Become known as the “growth guy”. Changed my twitter bio
to start:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1122px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "21.999999999999996%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHbkS4X/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGRABAAIDAAAAAAAAAAAAAAAAEQABECEx/9oACAEBAAE/IaoTUbe4/9oADAMBAAIAAwAAABD3z//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQACAwAAAAAAAAAAAAAAAAEAETFBYf/aAAgBAQABPxCzZloIIBUrrP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan's Twitter Bio",
          "title": "Shaan's Twitter Bio",
          "src": "/static/95a5950de7fc6b1b4062ecd005ce4bbb/3e80e/shaans-twitter-bio.jpg",
          "srcSet": ["/static/95a5950de7fc6b1b4062ecd005ce4bbb/a6407/shaans-twitter-bio.jpg 400w", "/static/95a5950de7fc6b1b4062ecd005ce4bbb/a24e6/shaans-twitter-bio.jpg 800w", "/static/95a5950de7fc6b1b4062ecd005ce4bbb/3e80e/shaans-twitter-bio.jpg 1122w"],
          "sizes": "(max-width: 1122px) 100vw, 1122px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`While the audience grows over the next month, I’m going to nail the course
curriculum by doing mini workshops to experiment and see what resonates/helps
students the most.`}</p>
    <p>{`I think I can knock out 5-6 of these workshops over the next few weeks, and that
will become the foundation of my course content.`}</p>
    <p>{`80% of the energy should go towards building audience, 20% towards
curriculum/content.`}</p>
    <p>{`AAP members attend all workshops for free. First one is this sunday and it’s
about how to tell your story (nailing your 1-liner) so that customers “just get
it” right away. It clicks.`}</p>
    <p><a parentName="p" {...{
        "href": "https://hopin.com/events/nail-your-story?code=f0125560-06d9-41a6-aa89-eecf6ad5399c"
      }}><strong parentName="a">{`RSVP here`}</strong></a>{`.
See ya at Sunday’s workshop.`}</p>
    <p>{`-`}{` Shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: '/app/posts/behind-the-scenes-of-the-first-fund-lp-update',
        label: 'Behind The Scenes Of The First Fund LP Update Post'
      }, {
        url: 'https://docs.google.com/document/d/1OEbOF-Gz9a324aOEZaRez9f0KieqqMSFUBqUtYfWt0Y/edit',
        label: 'Growth Kickoff Google Doc'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://theflywheel.substack.com/',
        label: 'The Flywheel Substack'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.just_the_best_stuff_so_far.label,
      slug: slugify(postMap.just_the_best_stuff_so_far.slug)
    }} nextButton={{
      text: postMap.im_obsessed_with_this_business_model.label,
      slug: slugify(postMap.im_obsessed_with_this_business_model.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      