import React, { useContext, useEffect } from 'react';
import { Router } from '@reach/router';
import Posts from 'components/post-collection';
import { postMap, slugify } from 'utils/post-map';
import PrivateRoute from 'components/private-route';
import AuthContext from 'context/auth-context';
import { navigate } from 'gatsby';

export default function App() {
  const { user } = useContext(AuthContext);

  // useEffect(() => {
  //   !user && navigate('/login');
  // }, []);

  return (
    <Router>
      <Posts.home path='/app/posts' />
      <PrivateRoute
        component={Posts.start_here}
        path={slugify(postMap.start_here.slug)}
      />
      <PrivateRoute
        component={Posts.project_kickoff}
        path={slugify(postMap.project_kickoff.slug)}
      />
      <PrivateRoute
        component={Posts.making_the_sales_map}
        path={slugify(postMap.making_the_sales_map.slug)}
      />
      <PrivateRoute
        component={Posts.nailing_our_pitch}
        path={slugify(postMap.nailing_our_pitch.slug)}
      />
      <PrivateRoute
        component={Posts.the_taste_of_rejection}
        path={slugify(postMap.the_taste_of_rejection.slug)}
      />
      <PrivateRoute
        component={Posts.going_down_the_funnel}
        path={slugify(postMap.going_down_the_funnel.slug)}
      />
      <PrivateRoute
        component={Posts.how_1_tweet_turned_into_1_5_m}
        path={slugify(postMap.how_1_tweet_turned_into_1_5_m.slug)}
      />
      <PrivateRoute
        component={Posts.sometimes_you_need_to_be_a_worker_ant}
        path={slugify(postMap.sometimes_you_need_to_be_a_worker_ant.slug)}
      />
      <PrivateRoute
        component={Posts.what_do_vacuums_gummies_chalkboards_all_have_in_common}
        path={slugify(
          postMap.what_do_vacuums_gummies_chalkboards_all_have_in_common.slug
        )}
      />
      <PrivateRoute
        component={Posts.amas_audio_experiments_and_the_sunday_mailbag}
        path={slugify(
          postMap.amas_audio_experiments_and_the_sunday_mailbag.slug
        )}
      />
      <PrivateRoute
        component={Posts.impulse_vs_repeat_vs_splurge_products}
        path={slugify(postMap.impulse_vs_repeat_vs_splurge_products.slug)}
      />
      <PrivateRoute
        component={Posts.the_power_box}
        path={slugify(postMap.the_power_box.slug)}
      />
      <PrivateRoute
        component={Posts.validating_demand_for_product_ideas}
        path={slugify(postMap.validating_demand_for_product_ideas.slug)}
      />
      <PrivateRoute
        component={Posts.tools_tips_tricks_to_finding_a_manufacturer}
        path={slugify(postMap.tools_tips_tricks_to_finding_a_manufacturer.slug)}
      />
      <PrivateRoute
        component={Posts.every_day_is_my_friday}
        path={slugify(postMap.every_day_is_my_friday.slug)}
      />
      <PrivateRoute
        component={Posts.product_samples_are_on_the_way}
        path={slugify(postMap.product_samples_are_on_the_way.slug)}
      />
      <PrivateRoute
        component={Posts.good_artists_copy_great_artists_steal}
        path={slugify(postMap.good_artists_copy_great_artists_steal.slug)}
      />
      <PrivateRoute
        component={Posts.hiring_a_good_operator_feels_like_magic}
        path={slugify(postMap.hiring_a_good_operator_feels_like_magic.slug)}
      />
      <PrivateRoute
        component={Posts.bankshots_only_work_in_basketball}
        path={slugify(postMap.bankshots_only_work_in_basketball.slug)}
      />
      <PrivateRoute
        component={Posts.back_again_with_the_sunday_mailbag}
        path={slugify(postMap.back_again_with_the_sunday_mailbag.slug)}
      />
      <PrivateRoute
        component={Posts.using_big_mario_to_grow_the_aap_audience}
        path={slugify(postMap.using_big_mario_to_grow_the_aap_audience.slug)}
      />
      <PrivateRoute
        component={Posts.spoiler_alert_targeting_tai_lopez_s_audience_is_working}
        path={slugify(postMap.spoiler_alert_targeting_tai_lopez_s_audience_is_working.slug)}
      />
      <PrivateRoute
        component={Posts.prototyping_the_third_door}
        path={slugify(postMap.prototyping_the_third_door.slug)}
      />
      <PrivateRoute
        component={Posts.its_all_about_taking_the_right_risks}
        path={slugify(postMap.its_all_about_taking_the_right_risks.slug)}
      />
      <PrivateRoute
        component={Posts.the_internet_doesnt_care_about_geography}
        path={slugify(postMap.the_internet_doesnt_care_about_geography.slug)}
      />
      <PrivateRoute
        component={Posts.recap}
        path={slugify(postMap.recap.slug)}
      />
      <PrivateRoute
        component={Posts.picking_the_mission_for_the_week}
        path={slugify(postMap.picking_the_mission_for_the_week.slug)}
      />
      <PrivateRoute
        component={Posts.hitting_a_plateau_should_be_like_greeting_an_old_friend}
        path={slugify(postMap.hitting_a_plateau_should_be_like_greeting_an_old_friend.slug)}
      />
      <PrivateRoute
        component={Posts.were_not_selling_saddles_here}
        path={slugify(postMap.were_not_selling_saddles_here.slug)}
      />
      <PrivateRoute
        component={Posts.fridays_ama_25_day_recap_on_the_way}
        path={slugify(postMap.fridays_ama_25_day_recap_on_the_way.slug)}
      />
      <PrivateRoute
        component={Posts.just_the_best_stuff_so_far}
        path={slugify(postMap.just_the_best_stuff_so_far.slug)}
      />
      <PrivateRoute
        component={Posts.debating_isn_t_just_for_presidents}
        path={slugify(postMap.debating_isn_t_just_for_presidents.slug)}
      />
      <PrivateRoute
        component={Posts.behind_the_scenes_of_the_first_fund_lp_update}
        path={slugify(postMap.behind_the_scenes_of_the_first_fund_lp_update.slug)}
      />
      <PrivateRoute
        component={Posts.its_monday_and_i_wanna_help_you_nail_your_story}
        path={slugify(postMap.its_monday_and_i_wanna_help_you_nail_your_story.slug)}
      />
      <PrivateRoute
        component={Posts.today_was_a_total_curveball}
        path={slugify(postMap.today_was_a_total_curveball.slug)}
      />
      <PrivateRoute
        component={Posts.twenty_four_hours_of_learning_about_collectibles}
        path={slugify(postMap.twenty_four_hours_of_learning_about_collectibles.slug)}
      />
      <PrivateRoute
        component={Posts.building_the_flywheel}
        path={slugify(postMap.building_the_flywheel.slug)}
      />
      <PrivateRoute
        component={Posts.painting_a_picture_while_tickling_the_brain}
        path={slugify(postMap.painting_a_picture_while_tickling_the_brain.slug)}
      />
      <PrivateRoute
        component={Posts.growth_is_math_art_science}
        path={slugify(postMap.growth_is_math_art_science.slug)}
      />
      <PrivateRoute
        component={Posts.the_art_of_non_violent_communication}
        path={slugify(postMap.the_art_of_non_violent_communication.slug)}
      />
      <PrivateRoute
        component={Posts.firing_lots_of_growth_bullets}
        path={slugify(postMap.firing_lots_of_growth_bullets.slug)}
      />
      <PrivateRoute
        component={Posts.finding_the_bug_in_the_system}
        path={slugify(postMap.finding_the_bug_in_the_system.slug)}
      />
      <PrivateRoute
        component={Posts.the_more_i_know_about_facebook_ads_the_better}
        path={slugify(postMap.the_more_i_know_about_facebook_ads_the_better.slug)}
      />
      <PrivateRoute
        component={Posts.i_hate_flying_blind_so_i_made_a_roas_calculator}
        path={slugify(postMap.i_hate_flying_blind_so_i_made_a_roas_calculator.slug)}
      />
      <PrivateRoute
        component={Posts.todays_ama_recording}
        path={slugify(postMap.todays_ama_recording.slug)}
      />
      <PrivateRoute
        component={Posts.how_id_make_100k_in_a_week_right_now}
        path={slugify(postMap.how_id_make_100k_in_a_week_right_now.slug)}
      />
      <PrivateRoute
        component={Posts.we_got_our_sample}
        path={slugify(postMap.we_got_our_sample.slug)}
      />
      <PrivateRoute
        component={Posts.im_obsessed_with_this_business_model}
        path={slugify(postMap.im_obsessed_with_this_business_model.slug)}
      />
      <PrivateRoute
        component={Posts.the_sunday_mailbag_a_special_guest_coming_this_week}
        path={slugify(postMap.the_sunday_mailbag_a_special_guest_coming_this_week.slug)}
      />
      <PrivateRoute
        component={Posts.tofu_isnt_just_something_i_like_to_eat}
        path={slugify(postMap.tofu_isnt_just_something_i_like_to_eat.slug)}
      />
      <PrivateRoute
        component={Posts.ripping_the_bandaid_off}
        path={slugify(postMap.ripping_the_bandaid_off.slug)}
      />
      <PrivateRoute
        component={Posts.my_life_vision}
        path={slugify(postMap.my_life_vision.slug)}
      />
      <PrivateRoute
        component={Posts.three_ecom_tactics_that_drove_30k_in_incremental_revenue}
        path={slugify(postMap.three_ecom_tactics_that_drove_30k_in_incremental_revenue.slug)}
      />
      <PrivateRoute
        component={Posts.my_5_lessons_learned_from_jack_butcher}
        path={slugify(postMap.my_5_lessons_learned_from_jack_butcher.slug)}
      />
    </Router>
  );
}
