import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Just The Best Stuff So Far`}</h1>
    <p><strong parentName="p">{`All Access Pass - October 13, 2020`}</strong></p>
    <p><strong parentName="p">{`I’ve sent you 25 emails in the past 30 days.`}</strong></p>
    <p>{`That’s a ton of content.`}</p>
    <p>{`Even with a high open rate (86.4%!) there’s no way someone could read & remember all that stuff.`}</p>
    <p>{`Not every email was great, but i know there were some nuggets of gold in there that have the potential to impact your life.`}</p>
    <p>{`So Ben & I sat down and tried to distill the 12 Biggest Takeaways from the all access pass so far.`}</p>
    <p>{`This is like when Lucky Charms released the “marshmallows only” version of their cereal. Just the best stuff.`}</p>
    <p><strong parentName="p">{`4 Best Techniques`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`4 Best Frameworks`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`4 Best Philosophies`}</strong></p>
    <p><strong parentName="p">{`= 12 best takeaways`}</strong>{`  `}</p>
    <p><a parentName="p" {...{
        "href": "https://docsend.com/view/jjsfyrbfppn3t68f"
      }}><strong parentName="a">{`Get the 12 Takeaways PDF here 🔑`}</strong></a>{`  `}</p>
    <p>{`lmk what you thought of the pdf,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <p><strong parentName="p">{`P.S. our plan is to keep that Docsend PDF link updated, as we get feedback and improve it (and you can download the pdf now too)`}</strong></p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docsend.com/view/jjsfyrbfppn3t68f',
        label: 'AAP 25-Day Recap - The 12 Biggest Takeaways PDF'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.hitting_a_plateau_should_be_like_greeting_an_old_friend.label,
      slug: slugify(postMap.hitting_a_plateau_should_be_like_greeting_an_old_friend.slug)
    }} nextButton={{
      text: postMap.building_the_flywheel.label,
      slug: slugify(postMap.building_the_flywheel.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      