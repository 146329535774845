import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Impulse vs. Repeat vs. Splurge Products`}</h1>
    <p><strong parentName="p">{`Day 9 - Today - Narrowing in On Product Ideas`}</strong></p>
    <p>{`Day 8 was all about generating ecomm ideas.`}</p>
    <p>{`Today - MOAR ideas!`}</p>
    <p>{`By the end of the week - I want to have our samples ordered.`}</p>
    <p><strong parentName="p">{`The order of operations here is:`}</strong></p>
    <p>{`Brainstorm alone → Brainstorm w/ Friends → Narrow it down → Get Quotes → Order
Samples.`}</p>
    <p><strong parentName="p">{`My OBT for today:`}</strong>{` get a few convos w/ friends going, brainstorm with them to
get a solid set of ideas to narrow down.`}</p>
    <p><strong parentName="p">{`So - today we kick off the Brainstorm w/ Friends step. Ben and I quickly made
a list of 5-6 friends who have done it before (built 1 or more ecom stores worth
`}{`$`}{`5M+)`}</strong></p>
    <ul>
      <li parentName="ul">{`Nathan - founder of Sourcify, maybe he can help with ideas + sourcing.`}</li>
      <li parentName="ul">{`Alex - started a makeup ecommerce company`}</li>
      <li parentName="ul">{`John - founder of hydrant, d2c beverage co`}</li>
      <li parentName="ul">{`Leland - member of one of my mastermind groups, founder of Bumpbox, big
ecommerce box company`}</li>
      <li parentName="ul">{`Jeff - founder of fat snax, killing it w keto snacks`}</li>
      <li parentName="ul">{`Suli - lot of ecomm experience, as an investor (hint water, sir kensington)
and operator`}</li>
    </ul>
    <p><strong parentName="p">{`These are people I know. But if I didn't here's what I would do (pls use
this):`}</strong></p>
    <p><strong parentName="p">{`DM them on twitter:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Me:`}</strong>{` "Yo - `}{`[name of their thing]`}{` is dope. Just bought my first one, love
the packaging"`}</li>
      <li parentName="ul"><strong parentName="li">{`Them:`}</strong>{` They say something like "hey thanks 🙏"`}</li>
      <li parentName="ul"><strong parentName="li">{`Me:`}</strong>{` And then I say something like "I've got an ecom project I'm cooking
up. It's a `}<strong parentName="li">{`_`}{`_`}{`_`}</strong>{` for `}<strong parentName="li">{`_`}{`_`}{`_`}</strong>{`. If you get a sec, would love to send you
a sample. Or something like "curious, which of these do you prefer for
packaging?"`}</li>
    </ul>
    <p><strong parentName="p">{`Notice ^`}</strong></p>
    <ul>
      <li parentName="ul">{`Start with a compliment`}</li>
      <li parentName="ul">{`Tell them what you're doing. Don't be a tease`}</li>
      <li parentName="ul">{`Don't ask for their time/coffee/call. They don't know you and don't want to
talk to you`}</li>
      <li parentName="ul">{`Instead, ask for quick feedback. Do you like A or B? (i like doing design
things, like product packaging, landing page design, etc… because everyone has
an opinion on visual things...and if your shit is well designed, it'll make
you seem legit to them.`}</li>
    </ul>
    <p>{`I've made a ton of friends like this. It's super simple. Look I did it the other
day with the founder of texts.com (insane that he got this domain btw).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "397px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "73.80352644836272%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABgACBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeqbWYQhL5P/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQQRAhIiA//aAAgBAQABBQKS6j75Vv6DaOjs/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/Aa1//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BJ//EAB0QAAIBBAMAAAAAAAAAAAAAAAABAhAhMZEyQWH/2gAIAQEABj8Cm/DvbOUt0s0Zif/EAB0QAAICAQUAAAAAAAAAAAAAAAABESExQVFx4fH/2gAIAQEAAT8hcgytBPnLcvbCVMh7g8EdQ//aAAwDAQACAAMAAAAQqO//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEVH/2gAIAQMBAT8QKx//xAAYEQACAwAAAAAAAAAAAAAAAAAAASFBUf/aAAgBAgEBPxBqI0//xAAgEAACAgEDBQAAAAAAAAAAAAABEQAhMUFRcZGh0fDx/9oACAEBAAE/ECOmwGIORqIEN0FIEd1EV6HMItVbQZBMM2us+18z/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Texts.com",
          "title": "Texts.com",
          "src": "/static/95c5ed50bdb2b8e041d6be6ff9072b9d/a8ee0/textsdotcom.jpg",
          "srcSet": ["/static/95c5ed50bdb2b8e041d6be6ff9072b9d/a8ee0/textsdotcom.jpg 397w"],
          "sizes": "(max-width: 397px) 100vw, 397px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`When I talk to them (either on the phone or email), it's going to be 3
questions:`}</strong></p>
    <ul>
      <li parentName="ul">{`What products have you seen/heard are killing it lately?`}</li>
      <li parentName="ul">{`(show them the list) - which products stand out as good or really bad on this
list?`}</li>
      <li parentName="ul">{`Who else should I talk to before picking my idea?`}</li>
    </ul>
    <p><strong parentName="p">{`In the meantime, I started bucketing the ideas from yesterday. I think about
ecom products in 3 categories:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The `}{`$`}{`15 impulse purchase`}</strong>{` - A
$15-20 product (eg. eyelash serum) that costs $1-2 to make. Someone can buy
this on a whim`}</li>
      <li parentName="ul"><strong parentName="li">{`The `}{`$`}{`40 repeat purchase`}</strong>{` - These products (eg. hair dye) do amazing with
ecommerce. A `}{`$`}{`40-50 purchase price, with monthly/bi-monthly repeat purchase.`}</li>
      <li parentName="ul"><strong parentName="li">{`The $120-$200 utility splurge`}</strong>{` - These products (eg. inflatable pools) have
lower conversion, but make up for it with higher price points/high margins.`}</li>
    </ul>
    <p>{`I'm aiming for bucket 2 or 3. #1 tends to be skating on thin ice. The price
point is so low, that if facebook ad costs go up, or supply costs change, the
whole business can go upside down quickly. #2 and #3 give us more buffer. #1 is
typically great as a checkout add-on to #2 or #3.`}</p>
    <p><strong parentName="p">{`Another framework:`}</strong></p>
    <p>{`Utility > Fun.`}</p>
    <p>{`I want to choose a product that's a utility or need, rather than something
that's fun/optional. Fun can work, but utility is a safer bet. It's a painkiller
rather than a vitamin.`}</p>
    <p><strong parentName="p">{`Timing:`}</strong></p>
    <p>{`Given the fact that we need to hit the market and be selling in the next 90
days, electronics are out. They just take too long to get right. And food is
iffy, since it typically takes awhile to get things like flavoring right.`}</p>
    <p><strong parentName="p">{`Narrowing:`}</strong></p>
    <p>{`So Ben took all that and
`}<a parentName="p" {...{
        "href": "https://docs.google.com/spreadsheets/d/1YtND318zK55LvuoNb4fBK9yc1PQqCNMEssh9TxfluSI/edit?usp=sharing"
      }}>{`categorized yesterday's ideas here`}</a>{`
(feel free to make your own copy of this!)`}</p>
    <p>{`We were able to take our original list of 22 product ideas down to 10 right away
…..`}</p>
    <p><strong parentName="p">{`So what's next? Tomorrow we have 3 convos scheduled with ecommerce friends -
that will be a huge help in getting closer to our main idea.`}</strong></p>
    <p>{`OK - time for dinner. Chicken and black beans. Instant pot for the win. Dinner
of champions.`}</p>
    <p>{`-shaan`}</p>
    <p>{`P.S. if you liked that
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=rkCkrh0Pxng&feature=youtu.be"
      }}>{`YouTube clip I sent of Geoff Lewis`}</a>{`
last week, you'll love `}<a parentName="p" {...{
        "href": "https://www.screenshotessays.com/"
      }}>{`screenshot essays`}</a></p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/spreadsheets/d/1YtND318zK55LvuoNb4fBK9yc1PQqCNMEssh9TxfluSI/edit?usp=sharing',
        label: 'Ecomm Ideation Chart Google Sheet'
      }, {
        url: 'https://www.youtube.com/watch?v=rkCkrh0Pxng&feature=youtu.be',
        label: 'Temp Check with Geoff Lewis'
      }, {
        url: 'https://www.screenshotessays.com/',
        label: 'Screenshot Essays'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.what_do_vacuums_gummies_chalkboards_all_have_in_common.label,
      slug: slugify(postMap.what_do_vacuums_gummies_chalkboards_all_have_in_common.slug)
    }} nextButton={{
      text: postMap.the_power_box.label,
      slug: slugify(postMap.the_power_box.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      