import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import ReactPlayer from 'react-player';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Back Again With The Sunday Mailbag`}</h1>
    <p>{`It’s sunday morning & I just cleaned off 40 cans of empty La Croix off my desk...`}</p>
    <p>{`Now it’s time to boogie and answer your questions for me in the second edition of the Mailbag...`}</p>
    <p>{`If you couldn't catch Friday’s AMA, `}<a parentName="p" {...{
        "href": "https://vimeo.com/462449344/cf57ae88ca"
      }}>{`you can watch the full hour here`}</a>{`  `}</p>
    <ReactPlayer url="https://vimeo.com/462449344/cf57ae88ca" mdxType="ReactPlayer" />
    <br />
    <p><strong parentName="p">{`The Sunday Mailbag:`}</strong></p>
    <p><strong parentName="p">{`EComm Product`}</strong></p>
    <p>{`With the physical products project you’re considering supplements. Are you sourcing those domestically or is that something that you can actually source overseas, as well? Which is better and why? Is it strictly a pricing thing if you’re getting it from overseas?`}</p>
    <p>{`-`}{` Darren, a Philosopher Capitalist`}</p>
    <p><strong parentName="p">{`For the supplements product (Apple cider vinegar gummies), we found a USA supplier. I typically look for quality first, and them economics second.`}</strong></p>
    <p>{`People think “china = cheap quality” but if you actually look into it, most goods manufactured overseas have higher quality than the same item made in america. Sadly america has fallen far behind in manufacturing capability / expertise.`}</p>
    <p>{`But for things like food/supplements - there is high quality suppliers and food labs here in the US - so we’ll use those.`}</p>
    <p>{`You’ve been going through the ecomm product recently. Once you’ve got the samples and they are looking good, how do you get the CONFIDENCE in the product to do your first big order (especially as some manufacturers like decent $$$ first orders) Eg. Kickstarter, pre order campaign, start building an audience, just go with market insights?`}</p>
    <p>{`-`}{` Stu, from Down Under`}</p>
    <p><strong parentName="p">{`Big orders are scary. That’s why it’s a good idea to try and pre-sell if you can. Either on kickstarter or on your own site. You can also do a “smoke test” where you put the products up on the site. Spend ~$500 on ads to drive traffic. And then see how many people buy. After they buy - you can refund all of them and say sorry we’re actually not ready yet!`}</strong></p>
    <p>{`Do you have a sample contract for ordering out of China?`}</p>
    <p>{`-`}{` Javier, shout out to Drake in the six`}</p>
    <p><strong parentName="p">{`I’ve never done contracts with chinese suppliers. Maybe I should..but in my mind there’s zero enforceability in the contract so I dont bother.`}</strong></p>
    <p>{`Would you recommend selling on Shopify or Amazon?`}</p>
    <p>{`-`}{` Javier, from the six`}</p>
    <p><strong parentName="p">{`Two completely different games here - Shopify is all about you bring your own demand and Amazon is you taking advantage of theirs. I know the shopify game better, so I focus on that. If you’re looking for amazon help - my friend tom runs a good course called `}<a parentName="strong" {...{
          "href": "https://www.fbamasterclass.ca/"
        }}>{`https://www.fbamasterclass.ca/`}</a></strong></p>
    <p>{`Do you have any tips for saving on Freight forwarder/shipping?`}</p>
    <p>{`-`}{` Javier, from Canada (I got you!)`}</p>
    <p><strong parentName="p">{`I use flexport and haven’t tried saving money here. I don’t worry about saving money until things get to big scale.`}</strong></p>
    <p>{`Is using facebook group owners to promote your product effective?`}</p>
    <p>{`-`}{` Javier, 4x in a row!`}</p>
    <p>{`It’s a catch-22.`}</p>
    <p>{`Owners who will let you promote, tend to have spammy groups with low engagement`}</p>
    <p><strong parentName="p">{`Owners with great groups won’t let you promote. So no, I don’t waste time on this, I just do fb ads.`}</strong></p>
    <p><strong parentName="p">{`Digital Product:`}</strong></p>
    <p>{`How would you go about pre-selling and validation for a digital product, if you don't have a pre-existing audience?`}</p>
    <p>{`-`}{` Emannuel aka Manny`}</p>
    <p><strong parentName="p">{`For a course - the best thing to do is to build an audience first. If you can’t get people to subscribe to your blog/newsletter, you probably won’t get them to buy a course either. Free content tends to be the best top of funnel for courses.`}</strong></p>
    <p>{`What’s your view on creating an online course production /marketing agency? Would find influencers across different niches then create and market online courses for them and split the revenue. Do you think that would work even though online courses have low barriers to entry with platforms like teachable?`}</p>
    <p>{`-`}{` Dillan from London`}</p>
    <p><strong parentName="p">{`I think it’s a good not great idea. Good because I think it will work and you can make some money. Not great though, because it’s a services model. Fundamentally service businesses suck because you’re trading hours for dollars.`}</strong></p>
    <p><strong parentName="p">{`Buying Businesses:`}</strong></p>
    <p>{`On the show, you guys have talked a lot about buying businesses and strategies around where to find businesses to buy. Back in season two, you had on Joe Valley from Quiet Light Brokerage who explained that there are really two ways to finance buying a business. SBA loan or your own cash / raising external funds from investors. He explained that it's extremely difficult for those without a track record of running a business to get approved for a loan. I wanted to hear more about how you would have gone about buying a business in your early 20's before you had experience running your own business and would have been able to personally finance it or get approved for an SBA.`}</p>
    <p>{`-`}{` Blake from the Big Apple`}</p>
    <p><strong parentName="p">{`You can get a SBA loan if the business has 3+ years of profitable history. The loan is based on the business track record (and not your own). So that’s how I would do it.`}</strong></p>
    <p>{`What are common themes of things you’ve seen work for people who have bought businesses successfully? What advice would you have for someone looking to buy a company off of quiet light like it’s described on the pod?`}</p>
    <p>{`-`}{` John`}</p>
    <p><strong parentName="p">{`The common theme is that they look for something that is:`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Durable - it’s not going to disappear / isn’t a fad business`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Has 1 or more clear growth lever the current owner hasn’t tried - eg. they don’t do paid marketing`}</strong></li>
    </ol>
    <p><strong parentName="p">{`My advice would be to talk to 5 people who have bought businesses like this before and ask them a list of questions. Then I would commit to looking at 20 deals without buying in order to “train my instincts” to know what a good vs. bad vs. amazing deal looks like.`}</strong></p>
    <p><strong parentName="p">{`The Fund`}</strong></p>
    <p>{`Have you thought about ways to allow your rolling fund to take investments in Bitcoin or Ethereum? I know you talked on your podcast about the problem of people who are crypto wealthy, but don’t want to take the tax hit of converting their crypto to dollars. Will there be a way to let people invest in startups with crypto?`}</p>
    <p>{`-`}{` Erik, from UCal!`}</p>
    <p><strong parentName="p">{`I’ve thought about it - but I’m using AngelList to host the fund, so I’m somewhat bound to what they can accept.`}</strong></p>
    <p><strong parentName="p">{`Me and Furqan have talked about raising a fund in crypto using a token (the cool thing would be that your investment interest is tradeable at all times, so it’s liquid). But meh too much work right now.`}</strong></p>
    <p>{`How much are you putting into the fund himself? Or is the plan to invest side by side in each deal, but outside the fund?`}</p>
    <p>{`-`}{` Mikhail, from the home of Lionel Messi`}</p>
    <p><strong parentName="p">{`I’m putting in ~$40k myself. Before this I was doing about $125-$150k per year via angel investing`}</strong></p>
    <p><strong parentName="p">{`The All Access Pass:`}</strong></p>
    <p>{`I understand that you are teaching via “shadowing,” but beyond this I don’t seem able to discern a clear curriculum design, or a framework you are providing in order to help your students organize and integrate what they are learning day after day. I don’t mean this as a criticism, but what I am wondering is if there is some larger framework or design that is thus far invisible to me?`}</p>
    <p>{`Right now, it feels like I’m getting a scattershot of a lot of good material, but I don’t know how to organize what I’m learning or how each day or week is meant or designed to build on what I learned previously.`}</p>
    <p>{`-`}{` Erik`}</p>
    <p>{`That’s a fair criticism. I’m not trying to “teach students” persay. I’m giving curious minds access to my mind.`}</p>
    <p>{`This isn’t a course. It’s meant to be a window into a world that most people don’t see (the messy process of building something from scratch).`}</p>
    <p>{`Business is fundamentally about problem solving. Every day you’re seeing an email that outlines a “problem” (eg. “I need to find a supplier” or “I need to convince strangers to invest in my fund”) - and how we attempt to solve it.`}</p>
    <p>{`I hope people can learn from this:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`What types of problems arise in a business`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`How we try to solve them (tactics, mentality etc.)`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`The level of action / intensity it takes to win`}</strong></li>
    </ul>
    <p><strong parentName="p">{`Shaan's Musings:`}</strong></p>
    <p>{`I liked your Impact v. Urgency chart as a framework for prioritization. Where do you account for time? For example, if something is quite low on the graph, but takes 10/20 minutes would you prioritize it higher?`}</p>
    <p>{`-`}{` Mikhail, from Barca`}</p>
    <p><strong parentName="p">{`Nope. I think this is a common mistake. Doing things just because they are “quick”. I’d rather do fewer (but higher impact) things than do lots of small things. Basically, I want to hunt elephants not mice.`}</strong></p>
    <p>{`Any tips on how you grew the My First Million podcast? - Arpan from NYC`}</p>
    <p><strong parentName="p">{`Main tip here is that i found a way to “hack” distribution by partnering with the hustle.`}</strong></p>
    <p><strong parentName="p">{`I made a list of media companies with big audiences, and no podcasts. Then I approached them offering to do a podcast on their platform.`}</strong></p>
    <p><strong parentName="p">{`I made it an easy decision. I said - I’ll do all the work, you get great content to provide to your audience, and we’ll split the revenue 50/50. The Hustle said yes.`}</strong></p>
    <p>{`What do you look for when you're finding someone (like Ben) to work with on a project? And what advice can you give to someone who wants to put themselves in those kinds of positions to work with someone like yourself?`}</p>
    <p>{`-`}{` Toby from NZ`}</p>
    <p><strong parentName="p">{`Ben was a customer of mine. He was in my mastermind group I created for business owners with over $1m+ in revenue. Over a few months, I saw him in the mastermind groups and came away with the impression that he was clever and unselfish. So when the opportunity came up, I asked him if he’d want to help me work on this.`}</strong></p>
    <p><strong parentName="p">{`To get in those positions - create something / make something that shows your skills. I saw Ben build a successful business, so I respected his abilities and didn’t need to “interview” him. Create something, so you have proof that you’re good at what you do.`}</strong></p>
    <p>{`If you had $70k and strong technical background yet a full time job would you A) Buy a side hustle, or B) Buy your way into a cap table and commit ~20 hours/week to that. - Tompa (Tom from Tampa, is that you TB12?)`}</p>
    <p><strong parentName="p">{`Neither. I would buy a small website/app that’s already profitable and try to grow it. I’d look through the app store, cold email people, and keep an eye on brokerage websites (eg. Quietlight brokerage) for potential apps to buy.`}</strong></p>
    <p>{`What tools would you recommend to automate document creation? For example, a customer / client completes form on website and that info pre-fills in a template that I can review on the back-end. I’d like to ask the questions in a user-friendly series of questions similar to my experience to ask this question.`}</p>
    <p>{`-`}{` Bobby`}</p>
    <p><strong parentName="p">{`Ben’s the expert at this type of stuff - i think what you’re looking for is typeform though!`}</strong></p>
    <p>{`Why are you working on small projects < 10MM$ projects, when with your influence and the circle you can do big VC funded company which solves some problem that you love.`}</p>
    <p>{`-`}{` Mehmood`}</p>
    <p><strong parentName="p">{`Good question. I still have a full-time day job at Twitch, so I don’t have the bandwidth to start a new company unless I leave. I enjoy Twitch, and have a strong financial incentive to stay, so I’m happy doing that as my day job, and doing stuff like this in my free time.`}</strong></p>
    <p><strong parentName="p">{`On top of that, I have zero desire to raise VC funding. I think I can build something much more lucrative for myself as a solopreneur or buying existing profitable businesses with an SBA loan and growing it`}</strong></p>
    <p>{`It's sometimes hard to get contrarian feedback from your team, do you have any methods for getting feedback or diverse viewpoints before making big decisions?`}</p>
    <p>{`-`}{` Rick, from Tim Duncan’s stomping grounds`}</p>
    <p><strong parentName="p">{`I specifically ask them questions to pull out contrarian opinions:`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`“what’s the worst thing about our plan”`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`“if I died tomorrow, would you keep working on this?`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`“What’s something you’ve thought in your head, but didn’t say because you didn’t want to rock the boat”`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`“I want this to be a place where people feel comfortable disagreeing or debating ideas. So let’s do it. I’ll take this position, who can I count on to debate the other position? Just for argument’s sake`}</strong></li>
    </ol>
    <p>{`See ya tomorrow,`}<br parentName="p"></br>{`
`}{`Shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      