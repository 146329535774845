import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Bankshots Only Work in Basketball`}</h1>
    <p>{`Dear Blush - Today was a doozy.`}</p>
    <p>{`I had 7 startup pitch meetings back to back to back to...you get the idea.`}</p>
    <p>{`There’s a great phrase about startups. Every success is unique, but all failures
come down to the same handful of reasons…`}</p>
    <ul>
      <li parentName="ul">{`Founders fighting`}</li>
      <li parentName="ul">{`No demand for the product`}</li>
      <li parentName="ul">{`Crappy product`}</li>
      <li parentName="ul">{`Poor unit economics`}</li>
      <li parentName="ul">{`Regulation/Legal issues`}</li>
    </ul>
    <p>{`But there’s one form of failure that is a really common trap founders fall into
(I know, because I fell into this trap myself once).`}</p>
    <p>{`In fact, this trap is so tempting.. I created a rule to remind myself not to
fall for it:`}</p>
    <p>{`“No Bankshots”`}</p>
    <p>{`Hang tight - I’ll explain in a second.`}</p>
    <p>{`What the hell is a bankshot?`}</p>
    <p>{`For any basketball fans - you already know. A bankshot is when a basketball
player shoots the ball and intentionally bounces it off the backboard before it
goes through the hoop.`}</p>
    <p>{`You’re basically aiming at the backboard (which scores you zero points), and
hoping that if you hit the backboard at just the right angle..you can then
ricochet into the hoop (which counts for points).`}</p>
    <p>{`In basketball this works. In business it doesn’t.`}</p>
    <p>{`As `}<a parentName="p" {...{
        "href": "http://blog.eladgil.com/2012/10/startups-miracles.html"
      }}>{`Elad Gil`}</a>{` puts it:`}</p>
    <p>{`you know a startup is likely to fail when it’s product strategy or business plan
includes multiple miracles… a startup miracle is the `}<strong parentName="p">{`one key`}</strong>{` difficult thing
you need to pull off for your startup to work.`}</p>
    <p>{`If you need `}<strong parentName="p">{`zero`}</strong>{` miracles, this is probably not a defensible startup and
won’t ultimately be that valuable. If you need `}<strong parentName="p">{`two+`}</strong>{` miracles, it’s probably
not going to work. Because you’re multiplying a 1/1000 thing happening x another
1/1000 thing happening == a one in a million probability.`}</p>
    <p>{`One miracle is hard enough to pull off.`}</p>
    <p>{`Single Miracle company == an app like Calm.`}</p>
    <p>{`Calm was not an obvious winner early on. It was slow growth. The miracle they
needed was for people to start caring about their mental health like they do
their physical health. Their miracle was for mainstream america to start
searching for meditation solutions. Building an app, loading a library of audio
content..there was no risk there. They just needed that one miracle.`}</p>
    <p>{`What’s a double miracle (bankshot) company?`}</p>
    <p>{`I got pitched a music listening company the other day. They said they were going
to be the next Spotify. They said that Spotify sucks because it’s not very
social.`}</p>
    <p>{`And hey - we all know Music is social (just look at this picture from Coachella)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFmy3mqyIH/xAAYEAEBAQEBAAAAAAAAAAAAAAABAAMCIf/aAAgBAQABBQI1nQZ2uHzpm//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAEAAwAAAAAAAAAAAAAAAAAQITGB/9oACAEBAAY/AqINP//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFh/9oACAEBAAE/Icts+1D3DfCJeM+kSrfdRLS+AETfM//aAAwDAQACAAMAAAAQ6N//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8QmJf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8Q1X//xAAeEAACAgEFAQAAAAAAAAAAAAABEQAhQTFRcZGx8P/aAAgBAQABPxAZQGexQsComCvnG6CRdL9mngkyI2UwU4ghBgt3P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Music festival",
          "title": "Music festival",
          "src": "/static/e4d13ac4692b4f9b5f78bc113ef87877/04bec/music-festival.jpg",
          "srcSet": ["/static/e4d13ac4692b4f9b5f78bc113ef87877/a6407/music-festival.jpg 400w", "/static/e4d13ac4692b4f9b5f78bc113ef87877/a24e6/music-festival.jpg 800w", "/static/e4d13ac4692b4f9b5f78bc113ef87877/04bec/music-festival.jpg 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Unfortunately they have multiple miracles to achieve:`}</strong></p>
    <ul>
      <li parentName="ul">{`Get music licenses / rights from record labels to stream their music (Miracle
#1)`}</li>
      <li parentName="ul">{`Somehow get people to switch / cancel their spotify/pandora/apple music
subscription and decide to use this startup instead. (Miracle #2)`}</li>
    </ul>
    <p>{`Each of these miracles is unlikely. Combined? You’re more likely to find an
amish person at CES.`}</p>
    <p><strong parentName="p">{`Another one - right now a lot of people are super into crypto &
decentralization. They want to build the “decentralized uber / airbnb / facebook
etc..”`}</strong></p>
    <ul>
      <li parentName="ul">{`Miracle #1 - getting the masses to understand/adopt decentralized applications
(good luck)`}</li>
      <li parentName="ul">{`Miracle #2 - solving the supply/demand problem that Airbnb solved`}</li>
      <li parentName="ul">{`Miracle #3 - scaling a 50M+ user product on the blockchain (lots of phD level
computer scientists trying to figure this out)`}</li>
    </ul>
    <p>{`This is a rare triple bankshot.`}</p>
    <p>{`This is the big mistake founders make. They talk themselves into how the really
hard first thing will give them leverage / some angle to then do a second
miracle. They are going to shoot the ball at the backboard, and hope it
ricochet’s into the hoop.`}</p>
    <p>{`This is very different from expansion.`}</p>
    <p>{`Bankshot == First miracle is worth
$0, you’re hoping it ricochet’s you into a 2nd miracle worth $`}{`$`}</p>
    <p>{`Expansion == First miracle is worth
$$, and then you do a 2nd miracle worth $$`}{`$`}{`.`}</p>
    <p>{`(for example, Amazon was successful selling books, CDs etc… and then expanded
into web services with AWS which makes like `}{`$`}{`50B a year)`}</p>
    <p>{`Got it blush? No bankshots baby! Go for single-miracle-businesses.`}</p>
    <p>{`-shaan`}</p>
    <p>{`p.s. here's a cool website i found today that we may use for ecomm landing page
inspiration - `}<a parentName="p" {...{
        "href": "https://www.reallygoodstores.com/"
      }}>{`check it out`}</a></p>
    <Links links={[{
      title: LinkLabel.Web,
      links: [{
        url: 'http://blog.eladgil.com/2012/10/startups-miracles.html',
        label: 'Startups & Miracles by Elad Gil'
      }, {
        url: 'https://www.reallygoodstores.com/',
        label: 'Really Good Stores Ecomm Landing Page Inspiration'
      }]
    }]} mdxType="Links" />
    <PostFooter nextButton={{
      text: postMap.its_all_about_taking_the_right_risks.label,
      slug: slugify(postMap.its_all_about_taking_the_right_risks.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      