import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`The more i know about facebook ads, the better`}</h1>
    <p>{`We had `}<a parentName="p" {...{
        "href": "https://twitter.com/garrisonyang_"
      }}><strong parentName="a">{`Garrison Yang`}</strong></a>{` drop by the All Access Pass Slack today.`}</p>
    <p>{`The topic = Facebook Ads.`}</p>
    <p>{`Garrison is on the Disruptors team at FB (basically an internal swat team to help a few brands a year grow w the fb platform), and in our era, Facebook is one of the best growth engines out there. So the more we can learn, the better.`}</p>
    <p><strong parentName="p">{`I’ll summarize with the 5 most interesting things I learned (full transcript below)`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If you’re working with a small budget, you’re better off using a targeted list/audience. The bigger your budget, the more you should just be leaving it in the facebook machine learning algorithm’s hands to find you customers`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cool story about Calm vs. Headspace. Calm does a 7 day trial. Headspace does 14 day. Facebook keeps track of it’s ad performance in a 7 day window. So Calm had a tighter feedback loop. They knew within the 7 days if they were buying users profitably or not, and reinforced the FB algorithm. So they ramped up spend 2-3x faster and outperformed Headspace.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`As you scale past $5-10k, there’s something called a Signal Audit you can get done if you have a fb account manager, that should help you make sure you’re setup to grow.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Running effective fb ads is a lot about creative. Video > static, but you really need a mix, especially as you start to scale. FB doesn’t like showing the same creative many times to the same person, so refreshing it is important`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The five core rules for launching ads are (thanks to AAP member `}<a parentName="p" {...{
            "href": "https://twitter.com/lewiswf"
          }}><strong parentName="a">{`Lewis Fausett`}</strong></a>{` for these):`}</p>
      </li>
    </ul>
    <ol>
      <li parentName="ol">{`Target an audience that should want your product`}</li>
      <li parentName="ol">{`Get enough of the thing you want (i.e. email sign ups) in that audience for fb to learn`}</li>
      <li parentName="ol">{`Test different copy / creative till you start to see wins`}</li>
      <li parentName="ol">{`Make sure you optimize for an event that gets 25-50 conversions per week`}</li>
      <li parentName="ol">{`Spend 70-80% on Acquisition and the remainder on retargeting for evergreen`}</li>
    </ol>
    <p>{`Now, for the transcript, `}<a parentName="p" {...{
        "href": "https://www.notion.so/dbwong/Garrison-Yang-AMA-c37a2a44f4714ee3a41137286ffc5511"
      }}><strong parentName="a">{`get it right here`}</strong></a>{`.`}</p>
    <p>{`until tomorrow,`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://slack.allaccesspass.io/guest-ama-archive/ask-me-anything-with-garrison-yang',
        label: 'Garrison Yang AMA transcript'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/garrisonyang_',
        label: "Garrison Yang's Twitter"
      }, {
        url: 'https://twitter.com/lewiswf',
        label: "Lewis Fausett's Twitter"
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.finding_the_bug_in_the_system.label,
      slug: slugify(postMap.finding_the_bug_in_the_system.slug)
    }} nextButton={{
      text: postMap.i_hate_flying_blind_so_i_made_a_roas_calculator.label,
      slug: slugify(postMap.i_hate_flying_blind_so_i_made_a_roas_calculator.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      