import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Every day is my Friday`}</h1>
    <p><strong parentName="p">{`One of my favorite quotes is from Conor McGregor.`}</strong></p>
    <p>{`The guy is always in the news for the wrong reasons - and might be an asshole..but he delivers some amazing quotables.`}</p>
    <p>{`The quote is from a press conference 2017. He had just become a Champion in the UFC, and became the wealthiest & most popular UFC fighter ever.`}</p>
    <p>{`The reporter asked him: “2015 has been quite the year for you. How do you top that next year?”`}</p>
    <p>{`McGregor: “2015 was my year...2016...is ALSO my year. Every year is My. Fucking. Year.”`}</p>
    <p>{`So cocky, I loved it. `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=k1LCiyWXqaw"
      }}>{`You can see the clip for yourself here at 11:52`}</a>{`.`}</p>
    <p>{`That’s how I choose to feel. Every year is my year. Every week is my week. Every day is my. fucking. day.`}</p>
    <p>{`Today is friday? Everyday is my. fucking. friday.`}</p>
    <p><strong parentName="p">{`OK anyways - with that outta the way. I have some housekeeping notes;`}</strong></p>
    <p>{`#1 - If you haven’t noticed, we take Fridays/Saturday’s off for the all access pass emails. See you sunday.`}</p>
    <p>{`#2 - Thanks to everyone who showed up at the AMA last week. We had over 100 ppl on the call. That’s like a small wedding. Since a lot of you liked it, we’re going to make it a regular thing. Next one is Friday 9/25, noon est. `}<del parentName="p"><a parentName="del" {...{
          "href": "http://lu.ma/wz2thgdh"
        }}>{`Click here to sign up`}</a></del>{`  `}</p>
    <p>{`On the call, I like to talk to you face to face. But I also will answer any `}<del parentName="p"><a parentName="del" {...{
          "href": "https://allaccesspass.typeform.com/to/cvzCBirc"
        }}>{`written questions here`}</a></del>{`.`}</p>
    <p>{`#3 - Text Messages`}</p>
    <p>{`I’m playing around with this new app called Community. Basically celebs like justin bieber, ellen, and Ninja use it to text with their fans. I’m excited to be the first D-List celebrity using it :) If you wanna try it out and get text messages from me with more content, just text “hi” to this number and follow the prompts: +1 (650) 334-0790`}</p>
    <p>{`#4 - We’re about to switch gears to the next $1M+ project. Hold onto your butts.`}</p>
    <p>{`Have a good friday,`}<br parentName="p"></br>{`
`}{`Shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      